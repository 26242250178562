<template>
  <div>
    <b-container>
      <b-modal id="share-test-modal" hide-footer class="p-3">
        <h3 id="share-test-modal-headline">
          Share the unique test link with your team
        </h3>
        <ShareLinkForm :clipboard-value="clipboardValue" />
        <b-link id="share-link" class="m-3" :href="'mailto:?subject=' + emailText.subject + '&body=' + getEmailBody()">
          <p><font-awesome-icon icon="envelope" /> &nbsp;Share test link via email</p>
        </b-link>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import ShareLinkForm from '@/components/ShareLinkForm';

export default {
  name: 'SPTestShareTestModal',
  components: { ShareLinkForm },
  props: {
    groupTestId: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      clipboardValue: 'default',
      emailText: {
        subject: 'You\'ve Been Invited to SolidProfessor Skills Analyzer',
        body: 'You\'ve been invited to test your skills with the SolidProfessor Skills Analyzer!'
      },
    };
  },
  created() {
    this.clipboardValue = this.computePlaceholder();
  },
  methods: {
    /**
     * Formats the placeholder string for the form copy-link box
     * @return {string}
     */
    computePlaceholder() {
      if (this.groupTestId) {
        return this.SPENV.WWW_URL + '/skillsgap/business/' + this.groupTestId;
      }
    },
    /**
     * Encodes the body of the mailto:
     * @returns {string}
     */
    getEmailBody () {
      return encodeURIComponent(this.emailText.body + '\n\n\nGo to the following link to take the test:\n' + this.clipboardValue);
    }
  }
};
</script>

<style lang="scss">
#share-test-modal {
  .blue-button {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    background: #00295B;
    border-color: #00295B;
    &:hover {
      background: #1A4A86;
      border-color: #1A4A86;
    }
  }
  .copy-link {
    margin-right: 0 !important;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }
  .go-wide {
    width: 70%;
  }
  .modal-header {
    padding-top: 4px;
    padding-bottom: 4px;
    border-bottom: 0;
  }
  .modal-body {
    padding: 24px;
  }
}
</style>
