<template>
  <div class="admin-results">
    <b-container v-if="test" class="p-5">
      <b-row class="my-3 mx-1">
        <b-col cols="12" md="9" class="p-0">
          <h3 id="test-title">
            Team Results for {{ test.test_name }}
          </h3>
          <p>{{ test.topic }}</p>
        </b-col>
        <b-col class="p-0">
          <b-button v-b-modal.share-test-modal block class="blue-button">
            <font-awesome-icon icon="share" size="sm" />
            Share Test Link
          </b-button>
        </b-col>
      </b-row>
      <b-row class="access-box my-4 mx-1 p-3">
        <b-col class="d-inline-flex">
          <h5><font-awesome-icon icon="envelope" /> How to access your results again</h5>
        </b-col>
        <b-col cols="12">
          <p>
            A link to your Results Dashboard has been sent to {{ test.email }}. Save the link so you can view results for this test at any time. If you need help, please call {{ SPENV.SUPPORT_PHONE }} or email <b-link :href="'mailto:' + SPENV.SUPPORT_EMAIL" class="blue-link">
              <b id="support-email">{{ SPENV.SUPPORT_EMAIL }}</b>
            </b-link>
          </p>
        </b-col>
      </b-row>
      <b-row class="text-center my-4">
        <b-col cols="12" md="3">
          <b-row class="full-height background-white mx-1" align-v="center">
            <b-col class="m-0 p-3">
              <b-row>
                <b-col>
                  <h5>Responses</h5>
                  <h1 v-if="testSummaryPerUser">
                    {{ Object.keys(formatParticipantsForTable(testSummaryPerUser)).length }}
                  </h1>
                  <b-link v-scroll-to="{ el:'#user-results', duration:1000}" href="#">
                    View Responses
                  </b-link>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="9">
          <b-row class="mx-1">
            <b-col class="background-white m-0 p-3">
              <b-row align-v="center">
                <b-col cols="8" offset="2" md="3"
                       offset-md="0">
                  <DoughnutChart v-if="chartData" :data="chartData" :options="chartOptions" />
                </b-col>
                <b-col cols="12" md="9" class="text-left">
                  <h5>Skills Analyzer score</h5>
                  <p>This score is an overall indicator of your team’s skill level in this topic, averaging all results from each participant.</p>
                  <b-link @click="openPdf(SPENV.CDN_URL + '/assets/client-app/skillsgap/Answer+Keys.pdf')">
                    View Answer Key
                  </b-link>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mx-1">
        <b-col v-if="sectionScores" class="p-0">
          <b-table :items="formatSectionsForTable(sectionScores)" :fields="sectionTableFields" responsive="sm">
            <template v-slot:head(name)="data">
              <h5>Average Result By Section</h5>
            </template>
            <template v-slot:cell(name)="row" class="mx-3">
              <b-row>
                <b-col class="mx-3">
                  {{ row.item.name }}
                </b-col>
              </b-row>
            </template>
            <template v-slot:head(correct)="data">
              <!-- Intentionally blank -->
            </template>
            <template v-slot:cell(correct)="row">
              <b-row>
                <b-col cols="9" class="padding-custom">
                  <b-progress :value="row.item.correct" :max="row.item.attempted" />
                </b-col>
                <b-col class="text-center">
                  <p>{{ computeScore(row.item.correct, row.item.attempted) }}</p>
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row id="user-results" class="mx-1">
        <b-col v-if="testSummaryPerUser" class="p-0">
          <b-row class="table-customer-background m-0 py-2">
            <b-col>
              <h5>Participant Results</h5>
            </b-col>
          </b-row>
          <b-table id="results-table" :items="formatParticipantsForTable(testSummaryPerUser)"
                   :fields="resultsTableFields" responsive class="m-0">
            <template v-slot:head(first_name)="data">
              <b-row>
                <b-col class="text-left mx-3">
                  <span>Name</span>
                </b-col>
              </b-row>
            </template>
            <template v-slot:cell(first_name)="row" class="text-center mx-3">
              <b-row>
                <b-col class="mx-3">
                  {{ row.item.first_name + ' ' + row.item.last_name }}
                </b-col>
              </b-row>
            </template>

            <template v-slot:head(correct)="data">
              <b-row>
                <b-col class="text-center">
                  <span>Score</span>
                </b-col>
              </b-row>
            </template>
            <template v-slot:cell(correct)="row">
              <b-row v-if="row.item.correct">
                <b-col class="text-center mx-3">
                  <p>{{ Math.round((row.item.correct / row.item.attempted) * 100) || 0 }} %</p>
                </b-col>
              </b-row>
            </template>

            <template v-slot:head(start_time)="data">
              <b-row>
                <b-col class="text-center">
                  <span>Duration</span>
                </b-col>
              </b-row>
            </template>
            <template v-slot:cell(start_time)="row">
              <b-row>
                <b-col class="text-center mx-3">
                  <p>{{ computeDuration(row.item.start_time, row.item.end_time) }}</p>
                </b-col>
              </b-row>
            </template>

            <template v-slot:head(end_time)="data">
              <!-- Intentionally blank -->
            </template>
            <template v-slot:cell(end_time)="row">
              <b-row>
                <b-col class="text-right mx-3">
                  <b-link @click="vueResults(row.item.uuid)">
                    <p>View Results &nbsp;<font-awesome-icon icon="external-link-alt" /></p>
                  </b-link>
                </b-col>
              </b-row>
            </template>
          </b-table>
          <b-row v-if="testSummaryPerUser.length === 0" class="table-customer-background m-0">
            <b-col class="text-center p-3">
              <p>A list of respondents will appear here once people take the test</p>
              <b-row>
                <b-col cols="12" lg="4" offset-lg="4">
                  <b-row>
                    <b-col cols="8" offset="2">
                      <b-button v-b-modal.share-test-modal block class="blue-button">
                        <font-awesome-icon icon="share" size="sm" />
                        Share Test Link
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <Banner v-if="test && overallScore && chartData && sectionScores" :banner-data="bannerData" />
    <SPTestShareTestModal :group-test-id="resultsId" />
  </div>
</template>

<script>
import DoughnutChart from '@/components/Elements/DoughnutChart';
import SPTestShareTestModal from '@/components/Skillsgap/SkillsgapShareTestModal.vue';
import Banner from '@/components/Banner';
import TimeToolsMixin from '@/utils/mixins/TimeTools';

export default {
  name: 'SkillsgapResultsVue',
  components: { Banner, DoughnutChart, SPTestShareTestModal },
  mixins: [TimeToolsMixin],
  data() {
    return {
      'bannerData': {
        button: {
          text: 'Schedule a demo',
          href:  process.env.SPENV.APP_URL + '/teams',
        },
        text: 'Get SolidProfessor for Business today and start upskilling for the future. Close skill gaps and prevent ' +
          'new ones from forming with online, on-demand engineering training for your team. Sign up for a free, ' +
          '30-minute demo to get a first-hand look at our training platform for your organization.',
        title: 'Ready to upskill your team?'
      },
      chartData: null,
      chartOptions: {
        cutoutPercentage: 70,
        legend: {
          align: 'end',
          position: 'bottom',
          display: false
        },
        elements: {
          center: {
            text: '50%'
          }
        },
        tooltips: {
          filter: function(item, data) {
            let label = data.labels[item.index];
            if (label) return item;
          }
        }
      },
      overallScore: 0,
      resultsId: null,
      resultsTableFields: ['first_name', 'correct', 'start_time', 'end_time'],
      sectionScores: 0,
      sectionTableFields: ['name', 'correct']
    };
  },
  computed: {
    test() {
      return this.$store.getters['sptests/getTest'];
    },
    testSummary() {
      return this.$store.getters['sptests/getTestSummary'];
    },
    testSummaryPerUser() {
      return this.$store.getters['sptests/getTestSummaryPerUser'];
    },
  },
  beforeCreate() {
    // We need all this, so get it
    let promise1 = this.$store.dispatch('sptests/getTest', this.$route.params['resultsId']);
    let promise2 = this.$store.dispatch('sptests/getSummary', this.$route.params['resultsId']);
    let promise3 = this.$store.dispatch('sptests/getAllUsersSummary', this.$route.params['resultsId']);
    let loader = this.$loading.show();
    Promise.all([promise1, promise2, promise3]).then(() => {
      this.overallScore = this.computeOverallScore();
      this.chartData = this.computeChartData();
      this.sectionScores = this.computeSectionData();
      loader.hide();
    });
  },
  created() {
    this.resultsId = this.$route.params['resultsId'];
  },
  methods: {
    /**
     * For the entire test, computes overall grade (total correct/total attempted)
     *
     * @return {number}
     */
    computeOverallScore() {
      let overall_attempted = 0;
      let overall_correct = 0;
      this.formatParticipantsForTable(this.testSummaryPerUser).forEach(test=>{
        overall_attempted = overall_attempted + test.attempted;
        overall_correct = overall_correct + test.correct;
      });
      return Math.round((overall_correct/overall_attempted) * 100);
    },
    /**
     * Creates the object to build the doughnut chart (passed as a param to the chart). The object is mostly static,
     * with the exception of the computed score (which should only be computed once)
     *
     * @return {object}
     */
    computeChartData() {
      return {
        datasets: [{
          data: [this.overallScore, 100 - this.overallScore],
          backgroundColor: [
            '#64A33D',
            '#AAAAAA'
          ],
          hoverBackgroundColor: [
            '#64A33D',
            '#AAAAAA'
          ],
          hoverBorderColor: [
            '#64A33D',
            '#ffffff'
          ]
        }],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: [
          'Percent Complete',
          'Incorrect'
        ]
      };
    },
    /**
     * Computes table text for correct/attempted ratio
     * Has a default return value in the event nothing has been attempted
     *
     * @param {number} correct
     * @param {number} attempted
     *
     * @return {string}
     */
    computeScore(correct, attempted) {
      let returnString = '--%';
      if (correct && attempted) {
        returnString = Math.round((correct / attempted) * 100) + '%';
      }
      return returnString;
    },
    /**
     * Wrapper to compute Section data for b-table. If summaries exist, grades by section, otherwise calls function
     * to just get section names
     * @return {associative array} -- section data formatted for b-table
     */
    computeSectionData() {
      let sections = {};
      if (this.testSummary && this.testSummary.answers && this.testSummary.count) {
        sections = this.computeSectionScores(this.testSummary);
      }
      else {
        sections = this.computeSectionNames(this.test);
      }
      return sections;
    },
    /**
     * For current Test, extract section names and give them a score of zero
     */
    computeSectionNames(test) {
      let sections = {};
      if (test && test.questions) {
        for (let [key, value] of Object.entries(test.questions)) {
          let section_id = value.section_id;
          sections[section_id] = {};
          sections[section_id]['name'] = value.section;
          sections[section_id]['attempted'] = 0;
          sections[section_id]['correct'] = 0;
        }
      }
      return sections;
    },
    /**
     * If the Test Summary has data, format it for the b-table
     * It is necessary to touch every answer 1 time in order to grade it
     *
     * @param {object} testSummary -- a test summary straight from API
     * @return {object} -- a graded test stummary by Section
     */
    computeSectionScores(testSummary) {
      let sections = {};
      if (testSummary && testSummary.answers && testSummary.count) {
        // Every question has to be counted, walk through each sub-object
        // For every bank of answers
        for (let [key, value] of Object.entries(testSummary.answers)) {
          // Grade every answer
          for (let [sub_key, sub_value] of Object.entries(value)) {
            if (sub_value && sub_value.answer_location && sub_value.answer_location.section) {
              let section_id = sub_value.answer_location.section;
              // If the section object does not exist in the return array , make it. This is the grade report
              if (!sections.hasOwnProperty(section_id)) {
                sections[section_id] = {};
                sections[section_id]['name'] = sub_value.answer_location.section_name;
                sections[section_id]['attempted'] = 0;
                sections[section_id]['correct'] = 0;
              }
              // Do the grading
              if (sub_value.selected_answer) {
                sections[section_id]['attempted'] += 1;
              }
              if (sub_value.correct_answer === sub_value.selected_answer) {
                sections[section_id]['correct'] += 1;
              }
            }
          }
        }
      }
      return sections;
    },
    /**
     * The Bootstrap Vue 'b-table' requires a flat list, so format our objects
     *
     * @param {object} obj -- associative array to extract the values from
     * @returns {ordered array} -- just the values from the obj submitted
     */
    formatSectionsForTable(obj){
      return  Object.values(obj);
    },
    /**
     * Screens each item to only show users that have answered all the questions (completed the test)
     *
     * @param sections -- associative array of Sections
     * @returns {ordered array} -- just the values from the Sections submitted
     */
    formatParticipantsForTable(sections) {
      let returnArray = [];
      Object.values(sections).forEach(section => {
        if (section.answers && section.attempted === this.test.questions.length) {
          returnArray.push(section);
        }
      });
      return  returnArray;
    },
    /**
     * Opens an external url in a new window (used to open Answer pdfs)
     * @param {string} url -- fully qualified url as string
     */
    openPdf(url) {
      window.open(url);
    },
    /**
     * Opens a new tab for Results submitted
     * @param testId
     * @param groupResultId
     */
    vueResults(groupResultUuid) {
      let navTo = this.$router.resolve({name: 'results', params: { groupTestId: this.$route.params['resultsId'], groupResultUuid: groupResultUuid }});
      window.open(navTo.href, '_blank');
    }
  },
};
</script>

<style lang="scss">
  .admin-results {
    background-color: #F8F8F8;
    .access-box {
      background: #D1ECF1;
      border: 1px solid #BEE5EB;
      box-sizing: border-box;
      border-radius: 4px;
    }
    .background-white {
      border-radius: 4px;
      border: 1px #e0e0e0 solid;
      background-color: #FFFFFF;
    }
    .blue-button {
      background: #00295B;
      border-color: #00295B;
      &:hover {
        background: #1A4A86;
        border-color: #1A4A86;
      }
    }
    .constrain-height {
      max-height: 300px;
    }
    .full-height {
      height: 100%;
    }
    h3 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
    }
    h5 {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-transform: capitalize;
      color: #414141;
    }
    .padding-custom {
      padding-top: 6px;
    }
    td {
      padding-top: 28px;
    }
    .table {
      background-color: #FFFFFF;
    }
    .table-customer-background {
      background-color: #FFFFFF;
    }
  }
</style>
