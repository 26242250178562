<script>
import { Doughnut } from 'vue-chartjs';

export default {
  extends: Doughnut,
  props: {
    data: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
    };
  },
  watch: {
    'data': {
      handler (newOption) {
        if (this._chart) {
          this._chart.destroy();
          this.renderChart(this.chartData, this.options);
        }
      },
      deep: true
    }
  },
  mounted () {
    this.renderChart(this.data, this.options);
    this.textCenter(this.data.datasets[0].data[0]);
  },
  methods: {
    /**
     * Creates the number inside the doughnut
     * @param val
     */
    textCenter(val) {
      Chart.pluginService.register({
        beforeDraw: function(chart) {
          let width = chart.chart.width,
              height = chart.chart.height,
              ctx = chart.chart.ctx;

          ctx.restore();
          let fontSize = (height / 64).toFixed(2);
          ctx.font = fontSize + 'em sans-serif';
          ctx.textBaseline = 'middle';

          val = isNaN(val) ? '--': val;

          let text = val + '%',
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2;

          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
