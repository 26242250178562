<template>
  <b-form inline class="share-link">
    <b-button-toolbar class="my-3 w-100">
      <b-button-group size="sm" class="fixed-height w-100">
        <b-input
          id="computed-link"
          v-model="clipboardValue" class="w-100" />
        <b-button id="copy-link-results" v-clipboard:copy="clipboardValue" v-clipboard:success="clipboardSuccess"
                  v-clipboard:error="clipboardError"
                  class="blue-button fixed-height w-50" @click="showTooltip = !showTooltip">
          <font-awesome-icon icon="link" size="sm" />
          Copy Link
        </b-button>
        <b-tooltip v-if="showTooltip" :show.sync="showTooltip" target="copy-link-results"
                   placement="bottom">
          Copied!
        </b-tooltip>
      </b-button-group>
    </b-button-toolbar>
  </b-form>
</template>

<script>
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';

VueClipboard.config.autoSetContainer = true; // NOTE: required https://www.npmjs.com/package/vue-clipboard2
Vue.use(VueClipboard);
export default {
  name: 'ShareLinkForm',
  props: {clipboardValue: { type: String, default: null }},
  data() {
    return {
      showTooltip: false,
    };
  },
  methods: {
    /**
     *  Error message if something goes wrong with copy to clipboard
     */
    clipboardError: function (e) {
      alert('Failed to copy texts');
    },
    /**
     * Show success tooltip, timeout hiding tooltip
     */
    clipboardSuccess() {
      this.showTooltip = true;
      setTimeout(()=>{
        this.showTooltip = false;
      }, 1500);
    },
  }
};
</script>

<style lang="scss">
  .share-link {
    .blue-button {
      background: #00295B;
      border-color: #00295B;
      &:hover {
        background: #1A4A86;
        border-color: #1A4A86;
      }
    }
    .fixed-height {
      height: 38px;
    }
  }
</style>
